import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";

function AdminPage({ googleAuthToken }) {
  const [name, setName] = useState("");
  const [apiKeyTag, setApiKeyTag] = useState("");
  const [addtionalApiKeyTag, setAddtionalApiKeyTag] = useState("");
  const [newCustomerResult, setNewCustomerResult] = useState("");
  const [additionalKeyResult, setAdditionalKeyResult] = useState("");
  const [customerIdInput, setCustomerIdInput] = useState("");
  const [customers, setCustomers] = useState([]);
  const [apiKeyToValidate, setApiKeyToValidate] = useState("");
  const [validationResult, setValidationResult] = useState("");
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [updateDescriptionResult, setUpdateDescriptionResult] = useState("");
  const [workflowCustomerId, setWorkflowCustomerId] = useState("");

  const { env, switchEnvironment } = useContext(EnvironmentContext);

  useEffect(() => {
    if (env !== "dev") {
      switchEnvironment("dev");
    }
  }, []);

  useEffect(() => {
    // Reset selections when environment changes
    setWorkflowCustomerId("");
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setUpdateDescriptionResult("");
  }, [env]);

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }, [googleAuthToken]);

  useEffect(() => {
    fetchCustomers();
  }, [env, fetchCustomers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer`,
        {
          name,
          apiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setNewCustomerResult(
        `Customer added successfully. API Key: ${response.data.apiKey}`
      );
    } catch (error) {
      setNewCustomerResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  async function addCustomerKey(customerId) {
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer key to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer-key`,
        {
          customerId,
          tag: addtionalApiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setAdditionalKeyResult(`New API Key added: ${response.data.apiKey}`);
    } catch (error) {
      setAdditionalKeyResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  }

  const validateApiKey = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/validate-api-key`,
        {
          apiKey: apiKeyToValidate,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setValidationResult(
        `Valid API key for customer: ${response.data.customerName} (Tag: ${response.data.tag})`
      );
    } catch (error) {
      setValidationResult(`Invalid API key`);
    }
  };

  const fetchWorkflows = async (customerId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/customer-workflows/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      const workflowObjects = response.data.map((workflow) => ({
        id: workflow.id,
        name: workflow.name,
        description: workflow.description,
      }));
      setWorkflows(workflowObjects);
      return workflowObjects;
    } catch (error) {
      console.error("Error fetching workflows:", error);
      return [];
    }
  };

  const handleCustomerChange = (e) => {
    const customerId = e.target.value;
    setCustomerIdInput(customerId);
  };

  const handleWorkflowCustomerChange = (e) => {
    const customerId = e.target.value;
    setWorkflowCustomerId(customerId);
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setUpdateDescriptionResult("");
  };

  useEffect(() => {
    if (workflowCustomerId) {
      fetchWorkflows(workflowCustomerId);
    }
  }, [workflowCustomerId]);

  const handleWorkflowChange = (e) => {
    const workflowName = e.target.value;
    setSelectedWorkflow(workflowName);
    const workflow = workflows.find((w) => w.name === workflowName);
    setWorkflowDescription(workflow?.description || "");
  };

  const updateWorkflowDescription = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to update this workflow description in the production environment?"
      )
    ) {
      return;
    }
    try {
      const workflow = workflows.find((w) => w.name === selectedWorkflow);
      if (!workflow) return;

      await axios.put(
        `${config.apiBaseUrl}/workflow-description/${workflow.id}`,
        {
          description: workflowDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setUpdateDescriptionResult("Workflow description updated successfully");

      if (workflowCustomerId) {
        const updatedWorkflows = await fetchWorkflows(workflowCustomerId);
        const updatedWorkflow = updatedWorkflows.find(
          (w) => w.name === selectedWorkflow
        );
        setWorkflowDescription(updatedWorkflow?.description || "");
      }
    } catch (error) {
      setUpdateDescriptionResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  return (
    <div className="AdminPage">
      <h1>Admin Tools</h1>
      <EnvSwitcher onEnvironmentSwitch={switchEnvironment} currentEnv={env} />
      <div className="container">
        <h2>Add New Customer</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="text"
              placeholder="Customer Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            <input
              type="text"
              placeholder="API Key Tag ('default' if left blank)"
              value={apiKeyTag}
              onChange={(e) => setApiKeyTag(e.target.value)}
            />
          </label>
          <button type="submit">Add Customer</button>
        </form>
        {newCustomerResult && <div className="result">{newCustomerResult}</div>}
      </div>
      <div className="container">
        <h2>Add Additional Key</h2>
        <div>
          <label>
            <select
              value={customerIdInput}
              onChange={(e) => setCustomerIdInput(e.target.value)}
            >
              <option value="">Select a customer</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            <input
              type="text"
              placeholder="API Key Tag"
              value={addtionalApiKeyTag}
              onChange={(e) => setAddtionalApiKeyTag(e.target.value)}
            />
          </label>
          <br />
          <button
            type="submit"
            onClick={() => addCustomerKey(customerIdInput, addtionalApiKeyTag)}
            disabled={!customerIdInput || !addtionalApiKeyTag}
          >
            Add Customer Key
          </button>
        </div>
        {additionalKeyResult && (
          <div className="result">{additionalKeyResult}</div>
        )}
      </div>
      <div className="container">
        <h2>Validate API Key</h2>
        <form onSubmit={validateApiKey}>
          <label>
            <input
              type="text"
              placeholder="Enter API Key to validate"
              value={apiKeyToValidate}
              onChange={(e) => setApiKeyToValidate(e.target.value)}
              required
            />
          </label>
          <button type="submit">Validate Key</button>
        </form>
        {validationResult && <div className="result">{validationResult}</div>}
      </div>
      <div className="container">
        <h2>Manage Workflow Descriptions</h2>
        <div>
          <label>
            <select
              value={workflowCustomerId}
              onChange={handleWorkflowCustomerChange}
            >
              <option value="">Select a customer</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            <select
              value={selectedWorkflow}
              onChange={handleWorkflowChange}
              disabled={!workflowCustomerId}
            >
              <option value="">Select Workflow</option>
              {workflows.map((workflow) => (
                <option key={workflow.id} value={workflow.name}>
                  {workflow.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <form onSubmit={updateWorkflowDescription}>
            <textarea
              placeholder="Workflow Description"
              value={workflowDescription}
              onChange={(e) => setWorkflowDescription(e.target.value)}
              disabled={!selectedWorkflow}
              rows={4}
              style={{ resize: "vertical" }}
            />
            <br />
            <button type="submit" disabled={!selectedWorkflow}>
              Update Description
            </button>
          </form>
          {updateDescriptionResult && (
            <div className="result">{updateDescriptionResult}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
